import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/dashboard/Login'),
    },
    {
      path: '/couriers/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Workload
        {
          name: 'Workload',
          path: 'workloads',
          component: () => import('@/views/dashboard/pages/Workload'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Workload
        {
          name: 'Workload',
          path: '',
          // component: () => import('@/views/dashboard/Dashboard'),
          component: () => import('@/views/dashboard/pages/Workload'),
        },
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: '/pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' && !store.getters['auth/isAuthorized']) {
    next()
  } else if (to.name === 'Login' && store.getters['auth/isAuthorized']) {
    router.push('/').catch(() => {})
  } else if (to.name !== 'Login' && !store.getters['auth/isAuthorized']) {
    router.push('/login').catch(() => {})
  } else if (to.name !== 'Login' && store.getters['auth/isAuthorized']) {
    next()
  } else {
    router.push('/login').catch(() => {})
  }
})

export default router
