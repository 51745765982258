import axios from 'axios'
import jwtDecode from 'jwt-decode'

const STORAGE_ACCESS_TOKEN_KEY = 'auth-accessToken'
const STORAGE_REFRESH_TOKEN_KEY = 'auth-refreshToken'

export default {
  async login (context, payload) {
    try {
      await context.dispatch('enableLoading', null, { root: true })
      const response = await axios.post(context.rootGetters.getApiHost + '/authorization/v1/login', {
        username: payload.username,
        password: payload.password,
      })

      context.commit('setAccessToken', response.data.access_token)
      context.commit('setRefreshToken', response.data.refresh_token)
      context.commit('setIsAuthorized', true)

      localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, response.data.access_token)
      localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, response.data.refresh_token)

      await context.dispatch('disableLoading', null, { root: true })

      return response.status === 200
    } catch (error) {
      await context.dispatch('disableLoading', null, { root: true })
      return false
    }
  },
  logout (context) {
    context.commit('setAccessToken', undefined)
    context.commit('setRefreshToken', undefined)
    context.commit('setIsAuthorized', false)

    localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY)
    localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY)
  },
  loginFromLocalStorage (context) {
    const accessTokenFromLocalStorage = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY)
    const refreshTokenFromLocalStorage = localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY)

    if (accessTokenFromLocalStorage && refreshTokenFromLocalStorage) {
      const decodeAccessToken = jwtDecode(accessTokenFromLocalStorage)
      if (decodeAccessToken.exp * 1000 > new Date().getTime()) {
        context.commit('setAccessToken', accessTokenFromLocalStorage)
        context.commit('setRefreshToken', refreshTokenFromLocalStorage)
        context.commit('setIsAuthorized', true)
      }
    }
  },
}
