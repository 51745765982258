export default {
  setRefreshToken (state, refreshToken) {
    state.refreshToken = refreshToken
  },
  setAccessToken (state, accessToken) {
    state.accessToken = accessToken
  },
  setIsAuthorized (state, isAuthorized) {
    state.isAuthorized = isAuthorized
  },
}
